/* CSS for HomePage component */

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
}

.logo img {
  margin-left: 50px;
  height: 200px;
  width: 150px;
}

.add-button {
  background-color: #df1e1e;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
  border-radius: 5px;
  margin-left: 200px;
  width: 250px;
  margin-right: 110px;
}

.add-button:hover {
  background-color: #003a4d;
}

/* Style for the client table */
.client-table {
  width: 80%;
  margin: 10px auto;
  border-collapse: collapse;
  border: 1px solid black; /* Add a black border around the table */
}

.client-table th,
.client-table td {
  border: 1px solid #ddd;
  padding: 10px;
  text-align: center;
  width: 50px;
}

.client-table th {
  background-color: #f5f5f5;
  font-weight: bold;
}

.client-table tr:nth-child(even) {
  background-color: #f0f0f0;
}

/* Style for the "View" button */
.view-button {
  background-color: #b94141;
  color: white;
  border: none;
  padding: 5px 10px;
  text-decoration: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
}

.view-button:hover {
  background-color: #c09595;
  color: white;
}

/* Style for error message */
.error-message {
  color: #df1e1e;
  font-size: 14px;
  margin-top: 10px;
}
/* CSS for the "Add Client" modal */
.modal {
  display: block;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  overflow: auto;
}

.modal-content {
  background-color: rgba(255, 255, 255, 0.8); /* Semi-transparent white background for the login form */
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(233, 209, 209, 0.5); /* Add a subtle shadow */
  width: 300px;
  margin-top: 140px;
  margin-left:500px;
  display: flex;
  flex-direction: column; /* Stack children vertically */
  align-items: center; /* Center content horizontally */
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
margin-left: 250px;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.error-message {
  color: #ff0000;
  margin-top: 10px;
}

/* CSS for Pagination */
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-left: 700px;
  width: 100px;
}

.pagination button {
  background-color: #eaeef1;
  color: rgb(14, 12, 12);
  border: none;
  padding: 10px 15px;
  cursor: pointer;
  margin: 0 5px;
  border-radius: 10px;
  font-size: 16px;
  
}

.pagination button:hover {
  background-color: #ebf0f5;
}

.pagination button:disabled {
  background-color: #ddd;
  color: #888;
  cursor: not-allowed;
}

.pagination .active {
  background-color: #e7edf3;
  color: rgb(14, 12, 12);
}
/* Add styles for client name input */
.client-name-input {
  padding: 10px;
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-bottom: 10px;
}

/* Add styles for create button */
.create-button {
  background-color: #df1e1e;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
  border-radius: 5px;
  
  width:150px;
}

.create-button:hover {
  background-color: #003a4d;
}




.logout-button {
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 10px 20px;
  margin: 0 10px;
  cursor: pointer;
  width: 150px;
}


.user-profile {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.user-icon {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}

.logout-popup {
  display: none;
  position: absolute;
  top: 60px;
  right: 0;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  padding: 10px;
  z-index: 1;
}

.logout-popup p {
  margin: 0;
  padding: 10px 0;
}

.logout-popup button {
  background-color: #f44336;
  color: white;
  border: none;
  padding: 5px 10px;
  margin-top: 10px;
  cursor: pointer;
}






