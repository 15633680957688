.client-details {
  display: flex;
  flex-direction: column;
  
  
  padding: 20px; 
 
}
.logo img {
margin-left: 50px;
height: 200px;
width: 150px;
}



.client-details h2 {
  font-size: 24px;
 margin-top: 0px;
  text-align: center;
  margin-right: 500px;
}



/* Style for the date filter section */
.date-filter {
  display: flex; /* Use flexbox for layout */
  justify-content: center; /* Center the content horizontally */
  align-items: center; /* Center the content vertically */
  
  text-align: center;
  margin-left: 140px;
}

.date-filter label {
  font-size: 16px;
  margin-right: 10px;
 
 
}

.date-filter input[type="date"] {
  padding: 5px;
  font-size: 16px;
  border: 1px solid #ddd;
  margin-right: 10px;
  
}

.date-filter button {
  background-color: #df1e1e;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
  border-radius: 5px;
  width: 80px;
  
 
  
}
.download button {
  background-color: #df1e1e;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
  border-radius: 5px;
  width: 150px;
  margin-left: 10px;
  
 
  
}

.date-filter button:hover {
  background-color: #0056b3;
}

/* Style for the messages table */
.messages-table {
  border-collapse: collapse;
  width: 100%;
  margin-top: 10px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid black;
}

/* Style for table headers (th) */
.messages-table th {
  background-color: #f5f5f5;
  text-align: center;
  padding: 5px;
  border: 1px solid black;
}

/* Style for table rows (tr) */
.messages-table tr {
  background-color: #fff;
}

/* Style for alternating row colors */
.messages-table tr:nth-child(even) {
  background-color: #f0f0f0;
}

/* Style for table cells (td) */
.messages-table td {
  padding: 5px;
  border: 1px solid black;
  font-size: 14px;
  width: 50px; /* Adjust the width as needed */
}
/* CSS for Pagination */
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-left:600px ;
}

.pagination button {
  background-color: #0056b3;
  color: white;
  border: none;
  padding: 10px 15px;
  cursor: pointer;
  margin: 0 5px;
  border-radius: 5px;
  font-size: 16px;
  width: 80px;
 
}

.pagination button:hover {
  background-color: #003f80;
}

.pagination button:disabled {
  background-color: #ddd;
  color: #888;
  cursor: not-allowed;
}
/* popup.css */
.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999; /* Ensure it's above other elements */
}

.popup-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  text-align: center;
}

.popup h3 {
  font-size: 24px;
  margin-bottom: 10px;
}

.popup p {
  font-size: 16px;
  margin-bottom: 20px;
}

.popup button {
  background-color: #007bff; /* Blue button color */
  color: #fff; /* White text color */
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.popup button:hover {
  background-color: #0056b3; /* Darker blue on hover */
}
.error {
  display: block;
  position: fixed;
  z-index: 1;
  
  top: 10px;
  
  height: 5px;
 
  background-color: rgba(236, 141, 141, 0.8);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(233, 209, 209, 0.5); 
  width: 200px;
  margin-top: 20px;
  margin-left:800px;
  
  text-align: center;
}